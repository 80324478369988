<script setup>
import { useAsyncStrapiApi } from "~/uses/useMyFetch";
import Subscription from "~/nuxt_modules/blog/ui/components/Subscription.vue";
import TagPosts from "~/nuxt_modules/blog/ui/pages/Blog/TagPosts.vue";
import { getPostsQuery } from "~/nuxt_modules/blog/utils/query.utils";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";
import { useLanguageStore } from "~/store/languageStore";
import NewestPosts from "~/nuxt_modules/blog/ui/pages/Blog/NewestPosts.vue";
import PostPreviewTags from "~/nuxt_modules/blog/ui/components/PostPreviewTags.vue";
import { normalizeStrapiUrl } from "~/utils/normalize";
import NewestPostPreview from "~/nuxt_modules/blog/ui/pages/Blog/NewestPostPreview.vue";

const { fetchCategories, fetchTags, tags } = useBlogStore();
const { language } = useLanguageStore();
const previews = ref(null);

const fetchPreviews = async () => {
  return await useAsyncStrapiApi(
    "fetchBlogPreviews",
    `/blog/posts?${getPostsQuery(language.name, 1, {}, 4)}`,
  ).then(({ data }) => {
    previews.value = data.value.data;
  });
};

// watch(
//   language,
//   async () => {
// console.log("we are in watcher???");
// await Promise.all([fetchPreviews(), fetchCategories(), fetchTags()]);
await fetchPreviews();
//   },
//   { immediate: true, deep: true },
// );

const newestPosts = computed(() => {
  return previews.value.slice(0, 3);
});

const fourthPreview = computed(() => {
  return previews.value[previews.value.length - 1];
});

// const { data: blogData } = await useAsyncData(
//   "fetchBlogData",
//   async () => {
//     const [previews] = await Promise.all([
//       useStrapiApi(`/blog/posts?${getPostsQuery(language.name)}`),
//       // useStrapiApi(`/blog/tags?${getTagsQuery(language.name)}`),
//       // new Promise((resolve) => resolve(blogStore.fetchCategories())),
//       // blogStore.fetchCategories(),
//     ]);
//
//     return {
//       previews: previews.data,
//     };
//   },
//   { watch: language, immediate: true, deep: true },
// );

// await fetchCategories();
</script>

<template>
  <div class="blog">
    <div class="blog__container">
      <NewestPosts :posts="newestPosts" />
      <TagPosts :post-previews="previews" :tag="tags[0]" />
      <NewestPostPreview :post="fourthPreview" />
      <Subscription />
    </div>
  </div>
</template>

<style scoped lang="scss">
.blog {
  padding-inline: 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 16px;

    margin-inline: auto;
  }
}
</style>
